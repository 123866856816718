import React from "react";

// Stylesheets
import "./Gallery.scss";

const Gallery = () => {
    return (
        <main className="gallery" id="gallery">
            <h1>Gallery</h1>
            <p>Here will be foto's...</p>
        </main>
    );
};

export default Gallery;