import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import TrackVisibility from "react-on-screen";

// Components
import ProjectCard from "../event/Event";

// Images
import projImg1 from "../../images/about/1.jpg";
import projImg2 from "../../images/about/2.jpg";
import projImg3 from "../../images/about/3.jpg";
import projImg4 from "../../images/about/4.jpg";
import projImg5 from "../../images/about/5.jpg";
import projImg6 from "../../images/about/6.jpg";
import projImg7 from "../../images/about/7.jpg";
import projImg8 from "../../images/about/8.png";
import projImg9 from "../../images/about/9.jpg";

// Stylesheets
import "animate.css";
import "./Events.scss";


const Events = () => {
    const events1 = [
        {
            title: "EVENT 1",
            description: "Coming soon...",
            imgUrl: projImg1,
        },
        {
            title: "EVENT 2",
            description: "Coming soon...",
            imgUrl: projImg2,
        },
        {
            title: "EVENT 3",
            description: "Coming soon...",
            imgUrl: projImg3,
        }
    ];

    
    const events2 = [
        {
            title: "EVENT 1",
            description: "Coming soon...",
            imgUrl: projImg4,
        },
        {
            title: "EVENT 2",
            description: "Coming soon...",
            imgUrl: projImg5,
        },
        {
            title: "EVENT 3",
            description: "Coming soon...",
            imgUrl: projImg6,
        }
    ];

    
    const events3 = [
        {
            title: "EVENT 1",
            description: "Coming soon...",
            imgUrl: projImg7,
        },
        {
            title: "EVENT 2",
            description: "Coming soon...",
            imgUrl: projImg8,
        },
        {
            title: "EVENT 3",
            description: "Coming soon...",
            imgUrl: projImg9,
        }
    ];

    return (
        <section className="events" id="events">
            <Container>
                <Row>
                    <Col size={12}>
                        <TrackVisibility>
                            {({ isVisible }) =>
                            <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                                <h1>Events</h1>
                                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
                                <Tab.Container id="tabs" defaultActiveKey="first">
                                    <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">Tab 1</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">Tab 2</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">Tab 3</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                                        <Tab.Pane eventKey="first">
                                            <Row>
                                                {
                                                    events1.map((project, index) => {
                                                        return (
                                                            <ProjectCard
                                                                key={index}
                                                                {...project}
                                                                />
                                                        )
                                                    })
                                                }
                                            </Row>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <Row>
                                                {
                                                    events2.map((project, index) => {
                                                        return (
                                                            <ProjectCard
                                                                key={index}
                                                                {...project}
                                                                />
                                                        )
                                                    })
                                                }
                                            </Row>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                            <Row>
                                                {
                                                    events3.map((project, index) => {
                                                        return (
                                                            <ProjectCard
                                                                key={index}
                                                                {...project}
                                                                />
                                                        )
                                                    })
                                                }
                                            </Row>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>}
                        </TrackVisibility>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Events;