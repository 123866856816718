import React, { useState } from "react";

// Images
import ngr_logo from "../../images/logos/ngr-logo.png";
import sgr_logo from "../../images/logos/sgr-logo.png";
import fgr_logo from "../../images/logos/fgr-logo.png";
import mgr_logo from "../../images/logos/mgr-logo.png";

// Stylesheets
import "./Platforms.scss";

const Platforms = () => {
    // State to control the visibility of the content
    const [isExtended, setIsExtended] = useState(false);

    // Function to toggle the visibility
    const toggleContent = () => {
        setIsExtended(!isExtended);
    };

    return (
        <section className="platforms" id="platforms">
            <h1>Platforms</h1>
            <div className={`wrapper ${isExtended ? "extended" : ""}`}>
                <div className="platform">
                    <a href="https://nutrigener.com" target="_blank" rel="noopener noreferrer">
                        <img src={ngr_logo} alt="NutriGeneR" />
                    </a>
                    <p>NutriGeneR was created with the idea of providing all our users with the best alternatives and solutions regarding health and nutrition.</p>
                    <p>Here we will find in a practical and didactic way information and products with the best advances and technology in the area of health and nutrition that our specialists and health professionals will share with us.</p>
                </div>
                <div className="platform">
                    <a href="https://sportgener.com" target="_blank" rel="noopener noreferrer">
                        <img src={sgr_logo} alt="SportGeneR" />
                    </a>
                    <p>SportGeneR was born with the idea of representing, managing and preparing athletes in their careers in different sports disciplines, but mainly in soccer.</p>
                    <p>SportGeneR focuses on fundamental values to achieve success, so everyone who is part of this family will find many benefits that will contribute in a very special way to your training.</p>
                    <p>Here you will be taught techniques to improve your self-image and your mental capacity to accept the challenge of constantly moving to the next level necessary to achieve your goals. Therefore personal development is essential.</p>
                    <p>All this combined with specific programs of nutrition and physical preparation will guarantee your success.</p>
                </div>
                <div className="platform">
                    <a href="https://fundgener.com" target="_blank" rel="noopener noreferrer">
                        <img src={fgr_logo} alt="FundGeneR" />
                    </a>
                    <p>FundGeneR is the financial part of the company where it will focus on helping its members to offer training and financial products to develop their business ideas or financial dreams.</p>
                    <p>Finance is an important part of people's lives, which is why it cannot go unnoticed, so financial opportunities will be created, coaching from experts and successful people in the world of finance who will teach us how to guarantee proper financial independence.</p>
                </div>
                <div className="platform">
                    <a href="https://mindgener.com" target="_blank" rel="noopener noreferrer">
                        <img src={mgr_logo} alt="MindGeneR" />
                    </a>
                    <p>MindGeneR in the academy that will allow us to have education on all aspects of the company.</p>
                    <p>This is the department of certified instructors that will help us understand the technical-academic aspects that encompass learning to achieve success in aspects of health, finances and personal development, fundamental pillars for a happy and successful life.</p>
                    <p>This is where our affiliates will be trained and prepared to function optimally to reach important and responsible positions within the company.</p>
                    <p>The people who attend this academy are the ones who will be able to enjoy other privileged advantages of economic benefits within the company.</p>
                </div>
            </div>
            <button onClick={toggleContent}>
                {isExtended ? "See Less" : "See More"}
            </button>
        </section>
    );
};

export default Platforms;