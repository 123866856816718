import React from "react";

// Stylesheets
import "./Footer.scss";

const Footer = () => {
    return (
        <footer
            id="footer"
            className={"footer section footer-dark"}
        >
            <div className={"container"}>
                <div className="row">
                    <div className="col-lg-6 text-center text-lg-start">
                        <p className="mb-3 mb-lg-0">
                            Copyright © 2023{" "}
                            <a
                                href="#top"
                                className="fw-500"
                            >
                                ViewGeneR
                            </a>
                            . All Rights Reserved.
                        </p>
                    </div>
                    <div className="col-lg-6">
                        <ul className="nav nav-separator justify-content-center justify-content-lg-end">
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-bs-toggle="modal"
                                    data-bs-target="#terms-policy"
                                    href="#terms-policy"
                                >
                                    Terms &amp; Policy
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-bs-toggle="modal"
                                    data-bs-target="#disclaimer"
                                    href="#disclaimer"
                                >
                                    Disclaimer
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;