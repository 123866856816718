import { useState, useEffect } from "react";

// Stylesheets
import "./NavBar.scss";

// Icons
import logo from "../../images/logos/vgr-logo-2.png";

const NAV_SCROLL = 50;

const NavBar = () => {
    const [scrolled, setScrolled] = useState(false);
    const [collapsedClass, setCollapsedClass] = useState("collapsed");
    const [hamburgerClass, setHamburgerClass] = useState("hamburger");

    const handleHamburger = () => {
        if (collapsedClass === "collapsed") {
            setCollapsedClass("collapsed visible");
        } else {
            setCollapsedClass("collapsed");
        }
    
        if (hamburgerClass === "hamburger") {
            setHamburgerClass("hamburger active");
        } else {
            setHamburgerClass("hamburger");
        }
    };

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY >= NAV_SCROLL) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        }

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    return (
        <nav className={scrolled ? "nav-bar scrolled" : "nav-bar"}>
            <a href="/" className="logo">
                <img src={logo} alt="Logo" />
            </a>
            <div className="links">
                <a href="#about" className="link">ABOUT</a>
                <a href="#platforms" className="link">PLATFORMS</a>
                <a href="#events" className="link">EVENTS</a>
                <a href="#team" className="link">TEAM</a>
                <a href="https://nutrigener.com" target="_blank" rel="noopener noreferrer" className="link">STORE</a>
            </div>
            <span className="signin">
                <a href="#footer">
                    <button><span>CONTACT</span></button>
                </a>
            </span>
            <div onClick={handleHamburger} className={hamburgerClass}>
                <div className="line line1"></div>
                <div className="line line2"></div>
                <div className="line line3"></div>
            </div>
            <div className={scrolled ? collapsedClass + " scrolled" : collapsedClass}>
                <a href="#about" className="link" onClick={handleHamburger}>ABOUT</a>
                <a href="#platforms" className="link" onClick={handleHamburger}>PLATFORMS</a>
                <a href="#events" className="link" onClick={handleHamburger}>EVENTS</a>
                <a href="#team" className="link" onClick={handleHamburger}>TEAM</a>
                <a href="https://nutrigener.com" target="_blank" rel="noopener noreferrer" className="link" onClick={handleHamburger}>STORE</a>
                <a href="#footer" className="link" onClick={handleHamburger}>CONTACT</a>
            </div>
        </nav>
    );
};

export default NavBar;