import React from "react";

// Images
import image1 from "../../images/about/1.jpg";
import image2 from "../../images/about/2.jpg";
import image3 from "../../images/about/3.jpg";

// Stylesheets
import "./About.scss";

const About = () => {
    return (
        <main className="about" id="about">
            <section className="first-section">
                <div className="info">
                    <span>Welcome!</span>
                    <h1>Generation with a New Vision</h1>
                    <div className="text">
                        <p>I’d like to personally welcome each of you to our special community. As we continue to grow and adapting permanently, remaining always motivated and responsive to grow a success community, we are open to new ideas to achieve the challenges. We are confronting a time of many nation-wide and global changes and we’re meeting these changes during a time where the politic, religion and commercial systems are affecting us directly or indirectly.</p>
                        <p>Apart from graduating with a bachelor's degree in Physical Sciences and Mathematics, I also studied Entrepreneurship and Business Marketing and at the same time worked as a professional soccer player.</p>
                        <p>All this experience allowed me to meet interesting and reputable people in the world of well-being and business.</p>
                        <p>It is from there that I was presented with the opportunity to create a project where we can help as many people as possible in the field of health and well-being in all possible aspects.</p>
                        <p>That is why we will be promoting and distributing nutritional supplements, vitamins and minerals from laboratories with which we have agreements to develop specific formulas and certified by the European community, therefore many of our laboratories are located in Europe to improve the physical condition of high performance athletes and people of all types and ages.</p>
                        <p>Also, as we advance in the growth of the company, we will incorporate more products and services, one of them is the distribution of 100% organic cocoa with all its derivatives.</p>
                        <p>So, it will be possible for our affiliates through our products and services to have the opportunity not only to enjoy the benefits of the products but also to be able to receive economic benefits from them.</p>
                        <p>I’d like to give you an idea of what you can expect and what we hope to achieve over the next few days months and years. The world of ViewGeneR is an exciting area in which to work, analyze and grow, an option to find a new generation of people focused on helping us understand the changes we are facing globally and how we can successfully face those changes. That is why we’ll continue to meet and bring inspired people together to ensure our organization remains at the cutting edge.</p>
                    </div>
                </div>
                <div className="images">
                    <img src={image1} alt="" />
                </div>
            </section>
            <section className="second-section">
                <div className="info">
                    {/* <span>Quote...</span> */}
                    <h1>WHere we are</h1>
                    <div className="text">
                        <p>Let me give you a brief update on where we are today. We are developing a project where people can enjoy of an educational coaching on personal development and self-image. This is with the aim of preparing us for the changes of a new economic and social generation.</p>
                        <p>We will also be associated with other specialized companies in what is the new macroeconomic trends that are being presented to also be able to provide more education and opportunities to create careers to achieve your own economic independence. As we grow, we will adapt new ideas and projects to just continue to offer more opportunities to our community.</p>
                        <p>We’re transforming the way we operate to continuously improve our ability to visionaries, execute and build the future. Our employees and partners have continued to meet the challenges of our field and to excel despite setbacks. We should all be very proud of where we are today and excited about where we are headed achieving goals.</p>
                    </div>
                </div>
                <div className="images">
                    <img src={image2} alt="" />
                </div>
            </section>
            <section className="third-section">
                <div className="info">
                    {/* <span>Quote...</span> */}
                    <h1>THe ViewGeneR Team</h1>
                    <div className="text">
                        <p>Before I close, I’d like to thank each of your for attending and believe on us. You, as organization future leaders, have the vision, the knowledge, the wherewithal and the experience to help us pave our way into the future. You are truly our greatest asset today and tomorrow, and we could not accomplish what we do without your support and leadership. Throughout this challenge, I ask you to stay engaged, keep us proactive and help us shape the future of ViewGeneR. My personal respect and thanks goes out to all of you.</p>
                        <p><b>Rene Valencia<br/>CEO FOUNDER<br/>ViewGeneR Holding Company</b></p>
                    </div>
                </div>
                <div className="images">
                    <img src={image3} alt="" />
                </div>
            </section>
        </main>
    );
};

export default About;