import React, { useEffect, useState } from "react";

// Stylesheets
import "./Home.scss"

// Icons
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// Components
import About from "../../components/about/About";
import Contact from "../../components/contact/Contact";
import Footer from "../../components/footer/Footer";
import Gallery from "../../components/gallery/Gallery";
import NavBar from "../../components/navbar/NavBar";
import PageLoader from "../../components/pageloader/PageLoader";
import Platforms from "../../components/platforms/Platforms";
import Projects from "../../components/events/Events";
import Team from "../../components/team/Team";
import Tooltip from "../../components/core/ToolTip";
import Top from "../../components/top/Top";

// Constants
const PAGE_LOADER = 1500;
const BACK_TO_TOP = 500;

const Home = () => {
    const [backToTop, setBackToTop] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadingTimeout = setTimeout(() => {
            setLoading(false);
        }, PAGE_LOADER);

        return () => {
            clearTimeout(loadingTimeout);
        };
    }, []);  

    const updateBackToTop = () => {
        if (document.body.scrollTop >= BACK_TO_TOP || document.documentElement.scrollTop >= BACK_TO_TOP) {
            setBackToTop(true);
        } else { 
            setBackToTop(false); 
        }
    };

    if (typeof window !== "undefined") { 
        window.addEventListener("scroll", updateBackToTop);
    }

    return (
        <div className="home">
            {loading && <PageLoader />}
            <NavBar />
            <Top />
            <About />
            <Platforms />
            <Projects />
            <Team />
            <Gallery />
            <Contact />
            <Footer />
            <Tooltip text="Back to Top" placement="left">
                <span
                    className="back-to-top"
                    style={{ display: backToTop ? "inline" : "none" }}
                    onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                >
                    <KeyboardArrowUpIcon className="centered" />
                </span>
            </Tooltip>
        </div>
    );
};

export default Home;