import React from "react";

// Stylesheets
import "./Team.scss";

const Team = () => {
    return (
        <main className="team" id="team">
            <h1>Team</h1>
            <p>Here will be foto's and text about the team...</p>
        </main>
    );
};

export default Team;