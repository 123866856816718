import React from "react";

// Stylesheets
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

// Components

// Routes
import Home from "./routes/home/Home";

const App = () => {
    return (
        <Home />
    );
};

export default App;