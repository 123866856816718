import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet } from "react-helmet";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Tab Icon
import favicon from './images/favicon.png';

// Components
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<>
    <Helmet>
        <title>ViewGeneR</title>
        <link rel="icon" type="image/png" href={favicon} />
    </Helmet>
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="*" element={ <main style={{ padding: "1rem" }}><p>This page does not exists...</p></main> } />
        </Routes>
    </BrowserRouter>
</>);