import React from "react";

// Images
import logo from "../../images/logos/vgr-logo.png";

// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Stylesheets
import "./Top.scss";

const Top = () => {
    return (
        <section className="top" id="top">
            <div className="bg" />
            <img src={logo} alt="Logo" className="logo centered"/>
            <a href="#about" className="scroll-down smooth-scroll">
                <KeyboardArrowDownIcon className="centered" />
            </a>
        </section>
    );
};

export default Top;