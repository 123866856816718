import React, { useRef } from "react";

// Stylesheets
import "./Contact.scss";

const Contact = () => {
    const form = useRef();

    return (
        <section id="contact" className="contact section">
            <div className="container">
                <div className="position-relative d-flex text-center mb-5">
                    <h1>Contact</h1>
                </div>
                {/* Heading end*/}
                <div className="row gy-5">
                    {/* contact details */}
                    <div className="left col-md-4 col-xl-3 order-1 order-md-0 text-center text-md-start">
                        <h2
                            className={
                                "mb-3 text-5 text-uppercase " + (true ? "text-white" : "")
                            }
                        >
                            Address info
                        </h2>
                        <p className={"text-3 mb-4 " + (true ? "text-light" : "")}>
                            Utrecht - The Netherlands
                            <br />
                            Btw-nummer: NL782178777B01
                            <br />
                            KvK-nummer: 90755502
                        </p>
                        <h2
                            className={
                                "mb-3 text-5 text-uppercase " + (true ? "text-white" : "")
                            }
                        >
                            Contact info
                        </h2>
                        <p className={"text-3 mb-1 " + (true ? "text-light" : "")}>
                            +31 647235882
                        </p>
                        <p className={"text-3 mb-4 " + (true ? "text-light" : "")}>
                            info@viewgener.com
                        </p>
                    </div>
                    {/* contact form */}
                    <div className="form col-md-8 col-xl-9 order-0 order-md-1">
                        <h2
                            className={
                                "mb-3 text-5 text-uppercase text-center text-md-start " +
                                (true ? "text-white" : "")
                            }
                        >
                            Send us an email
                        </h2>
                        <form
                            className={true ? "form-dark" : ""}
                            id="contact-form"
                            action="php/mail.php"
                            method="post"
                            ref={form}
                        >
                            <div className="row g-4">
                                <div className="col-xl-6">
                                    <input
                                        name="user_name"
                                        type="text"
                                        className="form-control"
                                        required
                                        placeholder="Name"
                                    />
                                </div>
                                <div className="col-xl-6">
                                    <input
                                        name="user_email"
                                        type="email"
                                        className="form-control"
                                        required
                                        placeholder="Email"
                                    />
                                </div>
                                <div className="col">
                                    <textarea
                                        name="message"
                                        className="form-control"
                                        rows={5}
                                        required
                                        placeholder="Tell us more about your needs........"
                                        defaultValue={""}
                                    />
                                </div>
                            </div>
                            <p className="text-center mt-4 mb-0">
                                <button
                                    id="submit-btn"
                                    className="btn btn-primary rounded-pill d-inline-flex"
                                    type="submit"
                                >
                                    Send Message
                                </button>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;