import React, { useEffect, useRef } from "react";
import { Tooltip } from "bootstrap";

const ToolTip = (element) => {
    const childRef = useRef(undefined);

    useEffect(() => {
        const tooltip = new Tooltip(childRef.current, {
            title: element.text,
            placement: element.placement,
            trigger: "hover"
        });

        return () => tooltip.dispose();
    }, [element.text, element.placement]);

    return React.cloneElement(element.children, { ref: childRef });
};

export default ToolTip;