import React from "react";

// Stylesheets
import "./PageLoader.scss"

const PageLoader = () => {
    return (
        <section className="page-loader">
            <div className="dots centered">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </section>
    );
};

export default PageLoader;