import { Col } from "react-bootstrap";

// Stylesheets
import "./Event.scss";

const Event = ({ title, description, imgUrl }) => {
    return (
        <Col size={12} sm={6} md={4} className="event">
            <div className="image">
                <img src={imgUrl} alt="" />
                <div className="text centered">
                    <h2>{title}</h2>
                    <p>{description}</p>
                </div>
            </div>
        </Col>
    );
};

export default Event;